import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, TextInput, View, Image, ScrollView, Platform, Button, TouchableOpacity, Dimensions } from 'react-native';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Formik,  } from 'formik';

const colors = {
    blue: '#4169E1',
};

// DEPLOY -----------------------: " npm run deploy-hosting "

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

export default function App() {
    const clearInputs = () => {
        
    }
    return (
        <SafeAreaProvider>
            <View style={styles.container}>
                <ScrollView>
                    <View style={[styles.page, styles.bookingPage]}>
                        <View style={[styles.titleContainer]}>
                            {Platform.OS === 'web' && screenHeight > 900 ? 
                                <Text style={styles.titleQuote}>"The greatest taxi in Iceland!"</Text>
                            : 
                                <View style={styles.textContainer}>
                                    <Text style={styles.title}>Haddi</Text>
                                    <Text style={[styles.title, {color: colors.blue}]}>Taxi</Text>
                                </View>
                            }
                            {Platform.OS === 'web' && screenHeight > 900 ? 
                                <View style={styles.textContainer}>
                                    <Text style={styles.title}>Haddi</Text>
                                    <Text style={[styles.title, {color: colors.blue}]}>Taxi</Text>
                                </View> 
                            : 
                                <Text style={styles.titleQuote}>"The greatest taxi in Iceland!"</Text>}
                        </View>
                        <View style={styles.bookingContainer}>
                            <View style={styles.bookingTextContainer}>
                                <View style={styles.textContainer}>
                                    <Text style={styles.paragraph}>call: </Text>
                                    <Text style={[styles.paragraph, {color: colors.blue, fontWeight: 'bold'}]}>777-3338</Text>
                                </View>
                                <Text style={[styles.paragraph, {fontWeight: 'bold'}]}>or</Text>
                                <Text style={[styles.paragraph, {textAlign: Platform.OS === 'web' && screenHeight > 900 ? 'left' : 'center'}]}>
                                    {Platform.OS === 'web' && screenHeight > 900 ? 'book a cab right here on the right.' : 'book a cab below.'}
                                </Text>	
                            </View>
                            <View style={styles.bookingInputContainer}>
                                <View style={styles.inputContainer}>
                                    <Formik
                                        initialValues={{ bookingName: '', pickup: '', dropoff: '', date: '' }}
                                        onSubmit={(values, actions) => {
                                            console.log(values)
                                            actions.resetForm();
                                        }}
                                    >
                                        {(props) => 
                                            <View style={styles.inputView}>
                                                <TextInput 
                                                    placeholder={'Name'} 
                                                    placeholderTextColor={'white'} 
                                                    style={[styles.paragraph, styles.input]} 
                                                    onChangeText={props.handleChange('bookingName')}
                                                    value={props.values.bookingName}
                                                />
                                                <TextInput 
                                                    placeholder={'Pickup location'} 
                                                    placeholderTextColor={'white'} 
                                                    style={[styles.paragraph, styles.input]}
                                                    onChangeText={props.handleChange('pickup')}
                                                    value={props.values.pickup} 
                                                />
                                                <TextInput 
                                                    placeholder={'Dropoff location'} 
                                                    placeholderTextColor={'white'} 
                                                    style={[styles.paragraph, styles.input]} 
                                                    onChangeText={props.handleChange('dropoff')}
                                                    value={props.values.dropoff} 
                                                />
                                                <TextInput 
                                                    placeholder={'Date and Time'} 
                                                    placeholderTextColor={'white'} 
                                                    style={[styles.paragraph, styles.input]}
                                                    onChangeText={props.handleChange('date')}
                                                    value={props.values.date} 
                                                />
                                                <TouchableOpacity style={[styles.bookingButton]}
                                                    onPress={()=>props.handleSubmit()}
                                                >
                                                    <Text style={[styles.buttonText]}>Book!</Text>
                                                </TouchableOpacity>
                                            </View>
                                        }
                                    </ Formik>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[styles.page, styles.infoPage]}>
                        {/* {Platform.OS === 'web' && screenHeight > 900 ? 
                                    <Text style={styles.titleQuote}>"The greatest taxi in Iceland!"</Text>
                        : 
                            <View style={styles.textContainer}>
                                <Text style={styles.title}>Haddi</Text>
                                <Text style={[styles.title, {color: colors.blue}]}>Taxi</Text>
                            </View>
                        }
                        {Platform.OS === 'web' && screenHeight > 900 ? 
                            <View style={styles.textContainer}>
                                <Text style={styles.title}>Haddi</Text>
                                <Text style={[styles.title, {color: colors.blue}]}>Taxi</Text>
                            </View> 
                        : 
                            <Text style={styles.titleQuote}>"The greatest taxi in Iceland!"</Text>
                        } */}
                        <View style={styles.infoContainer}>
                            <View style={styles.textContainer}>
                                <Text style={[styles.infoTitle, {color: colors.blue}]}>The Rider</Text>
                            </View>
                            <Text style={[styles.paragraph, styles.infoText]}>    
                                Haddi’s one mission is to get you 
                                from point A to point B in the most 
                                enjoyable way possible.
                            </Text>
                            <Image style={styles.img1} source={require('./assets/pictures/portrait1.png')}/>
                        </View>

                        <View style={styles.infoContainer}>
                            <View style={styles.textContainer}>
                                <Text style={[styles.infoTitle, {color: colors.blue}]}>The Ride</Text>
                            </View>
                            <Text style={[styles.paragraph, styles.infoText]}>    
                                Mercedes Benz 
                                e-class all terrain
                            </Text>
                            <Image style={styles.img1} source={require('./assets/pictures/car2.png')}/>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </SafeAreaProvider>
    );
}

const titleFontSize = Platform.OS === 'web' && screenHeight > 900 ? 100 : 70;
const subTitleFontSize = Platform.OS === 'web' && screenHeight > 900 ? 80 : 30;
const normalFontSize = Platform.OS === 'web' && screenHeight > 900 ? 40 : 30;
const paragraphFontSize = Platform.OS === 'web' && screenHeight > 900 ? 40 : 20;
const inputFontSize = Platform.OS === 'web' && screenHeight > 900 ? 30 : 15;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    page: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: Platform.OS === 'web' && screenHeight > 900 ? '100vw' : '100%',
        maxWidth: '100%',
        height: screenHeight,
        padding: Platform.OS === 'web' && screenHeight > 900 ? 80 : 10,
        // borderBottomWidth: 2,
        // borderBottomColor: '#404040',
    },
    paragraph: {
        fontSize: normalFontSize,
        textAlign: 'center',
        color: '#404040',
        fontWeight: '300',
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bookingPage: {
        justifyContent:  Platform.OS === 'web' && screenHeight > 900 ? 'flex-start' : 'center',
    },
        titleContainer: {
            marginBottom: 60,
        },
            titleQuote: {
                fontSize: subTitleFontSize,
                textAlign: 'center',
                color: '#404040',
            },
            title: {
                fontSize: titleFontSize,
                textAlign: 'center',
                color: '#404040',
                // fontFamily: Montserrat,
                // fontStyle: normal,
                // fontWeight: 300,
                // fontSize: 120,
                // lineHeight: 146,
            },
        bookingContainer: {
            flexDirection: Platform.OS === 'web' && screenHeight > 900 ? 'row' : 'column',
            justifyContent: Platform.OS === 'web' && screenHeight > 900 ? 'space-around' : 'space-between',
            width: Platform.OS === 'web' && screenHeight > 900 ? '70%' : '80%',
        },
            bookingTextContainer: {
                justifyContent: 'center',
                alignItems: Platform.OS === 'web' && screenHeight > 900 ? 'flex-start' : 'center',
                textAlign: 'center',
                width: Platform.OS === 'web' && screenHeight > 900 ? '40%' : '100%',
                marginBottom: Platform.OS === 'web' && screenHeight > 900 ? 0 : 40,
            },
            bookingInputContainer: {
                width: Platform.OS === 'web' && screenHeight > 900 ? '40%' : '100%',
                maxWidth: 350,
            },
                inputView: {
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    height: Platform.OS === 'web' && screenHeight > 900 ? '130%' : 250,
                },
                    input: {
                        width: '100%',
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        backgroundColor: colors.blue,
                        color: 'lightblue',
                        textAlign: 'left',
                        fontSize: inputFontSize,
                        
                        borderRadius: 5,
                    },
                    bookingButton: {
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '80%',
                        marginTop: 30,
                        padding: 10,
                        backgroundColor: 'white',
                        textAlign: 'center',
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,

                        elevation: 5,
                        borderRadius: 5,
                    },
                        buttonText: {
                            color: colors.blue,
                            fontSize: inputFontSize,
                            fontWeight: 'bold',
                        },
    infoPage: {
        justifyContent: Platform.OS === 'web' && screenHeight > 900 ? 'flex-start' : 'space-around',
        alignItems: 'center',
        width: '100%',
        paddingVertical: 40,
    },
        infoContainer: {
          justifyContent: 'center',
          alignItems: 'center',  
        },
        infoTitle: {
            fontSize: subTitleFontSize,
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#404040',
        },
        infoText: {
            fontSize: paragraphFontSize,
        },
        img1: {
            width: undefined, 
            height: Platform.OS === 'web' && screenHeight > 900 ? 200 : 150,
            maxWidth: '100%',
            maxHeight: '70%',
            marginTop: 20,
            aspectRatio: 16/9, 
            resizeMode: 'contain', 
        },
});
